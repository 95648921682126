import React from "react";

export default function Oferta() {
  const oferta = {
    backgroundColor: "#fff",
    padding: "15px",
  };

  const textCenter = {
    textAlign: "center",
  };
  return (
    <div style={oferta}>
      <p style={textCenter}>
        <strong>ПУБЛИЧНАЯ ОФЕРТА</strong>
      </p>
      <p style={textCenter}>
        <strong>о заключении договора об оказании услуг</strong>
      </p>
      <p>
        <br />{" "}
      </p>
      <p style={textCenter}>
        <strong>Общие положения</strong>
      </p>
      <p>
        В настоящей Публичной оферте содержатся условия заключения Договора об
        оказании услуг (далее по тексту - &laquo;Договор об оказании
        услуг&raquo; и/или &laquo;Договор&raquo;). Настоящей офертой признается
        предложение, адресованное одному или нескольким конкретным лицам,
        которое достаточно определенно и выражает намерение лица, сделавшего
        предложение, считать себя заключившим Договор с адресатом, которым будет
        принято предложение.
      </p>
      <p>
        Совершение указанных в настоящей Оферте действий является подтверждением
        согласия обеих Сторон заключить Договор об оказании услуг на условиях, в
        порядке и объеме, изложенных в настоящей Оферте.
      </p>
      <p>
        Нижеизложенный текст Публичной оферты является официальным публичным
        предложением Исполнителя, адресованный заинтересованному кругу лиц
        заключить Договор об оказании услуг в соответствии с положениями пункта
        2 статьи 437 Гражданского кодекса РФ.
      </p>
      <p>
        Договор об оказании услуг считается заключенным и приобретает силу с
        момента совершения Сторонами действий, предусмотренных в настоящей
        Оферте, и, означающих безоговорочное, а также полное принятие всех
        условий настоящей Оферты без каких-либо изъятий или ограничений на
        условиях присоединения.
      </p>
      <p>
        <strong>Термины и определения:</strong>
      </p>
      <p>
        <strong>Договор</strong> &ndash; текст настоящей Оферты с Приложениями,
        являющимися неотъемлемой частью настоящей Оферты, акцептованный
        Заказчиком путем совершения конклюдентных действий, предусмотренных
        настоящей Офертой.
      </p>
      <p>
        <strong>Конклюдентные действия &mdash; это</strong> поведение, которое
        выражает согласие с предложением контрагента заключить, изменить или
        расторгнуть договор. Действия состоят в полном или частичном выполнении
        условий, которые предложил контрагент.{" "}
      </p>
      <p>
        <strong>Сайт Исполнителя в сети &laquo;Интернет&raquo;</strong> &ndash;
        совокупность программ для электронных вычислительных машин и иной
        информации, содержащейся в информационной системе, доступ к которой
        обеспечивается посредством сети &laquo;Интернет&raquo; по доменному
        имени и сетевому адресу: https://tools.ptahini.ru/
      </p>
      <p>
        <strong>Стороны Договора (Стороны)</strong> &ndash; Исполнитель и
        Заказчик.
      </p>
      <p>
        <strong>Услуга</strong> &ndash; услуга, оказываемая Исполнителем
        Заказчику в порядке и на условиях, установленных настоящей Офертой.
      </p>
      <p style={textCenter}>
        <strong>Предмет Договора</strong>
      </p>
      <ol>
        <li>
          <p>
            Исполнитель обязуется оказать Заказчику Услуги, а Заказчик обязуется
            оплатить их в размере, порядке и сроки, установленные настоящим
            Договором.
          </p>
        </li>
        <li>
          <p>
            Наименование, количество, порядок и иные условия оказания Услуг
            определяются на основании сведений Исполнителя при оформлении заявки
            Заказчиком, либо устанавливаются на сайте Исполнителя в сети
            &laquo;Интернет&raquo; https://tools.ptahini.ru/{" "}
          </p>
        </li>
        <li>
          <p>
            Исполнитель оказывает Услуги по настоящему Договору лично, либо с
            привлечением третьих лиц, при этом за действия третьих лиц
            Исполнитель отвечает перед Заказчиком как за свои собственные.
          </p>
        </li>
        <li>
          <p>
            Договор заключается путем акцепта настоящей Оферты через совершение
            конклюдентных действий, выраженных в:
          </p>
        </li>
      </ol>
      <ul>
        <li>
          <p>
            действиях, связанных с регистрацией учетной записи на Сайте
            Исполнителя в сети &laquo;Интернет&raquo; при наличии необходимости
            регистрации учетной записи;
          </p>
        </li>
        <li>
          <p>
            оформлении и направлении Заказчиком заявки в адрес Исполнителя для
            оказания Услуг;
          </p>
        </li>
        <li>
          <p>действиях, связанных с оплатой Услуг Заказчиком;</p>
        </li>
        <li>
          <p>действиях, связанных с оказанием Услуг Исполнителем.</p>
        </li>
      </ul>
      <p>
        Данный перечень неисчерпывающий, могут быть и другие действия, которые
        ясно выражают намерение лица принять предложение контрагента.{" "}
      </p>
      <p style={textCenter}>
        <strong>Права и обязанности Сторон</strong>
      </p>
      <ol>
        <ol>
          <li>
            <p>
              <strong>Права и обязанности Исполнителя:</strong>
            </p>
            <ol>
              <li>
                <p>
                  Исполнитель обязуется оказать Услуги в соответствии с
                  положениями настоящего Договора, в сроки и объеме, указанные в
                  настоящем Договоре и (или) в порядке, указанном на Сайте
                  Исполнителя.
                </p>
              </li>
              <li>
                <p>
                  Исполнитель обязуется предоставлять Заказчику доступ к
                  разделам Сайта, необходимым для получения информации, согласно
                  пункту 2.1. Договора.
                </p>
              </li>
              <li>
                <p>
                  Исполнитель несет ответственность за хранение и обработку
                  персональных данных Заказчика, обеспечивает сохранение
                  конфиденциальности этих данных и использует их исключительно
                  для качественного оказания Услуг Заказчику.
                </p>
              </li>
              <li>
                <p>
                  Исполнитель оставляет за собой право изменять сроки (период)
                  оказания Услуг и условия настоящей Оферты в одностороннем
                  порядке без предварительного уведомления Заказчика, публикуя
                  указанные изменения на Сайте Исполнителя в сети
                  &laquo;Интернет&raquo;.{" "}
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </ol>
      <p>
        При этом новые / измененные условия, указываемые на Сайте, действуют
        только в отношении вновь заключаемых Договоров.
      </p>
      <ol>
        <ol start="2">
          <li>
            <p>
              <strong>Права и обязанности Заказчика:</strong>
            </p>
            <ol>
              <li>
                <p>
                  Заказчик обязан предоставлять достоверную информацию о себе
                  при получении соответствующих Услуг.
                </p>
              </li>
              <li>
                <p>
                  Заказчик обязуется не воспроизводить, не повторять, не
                  копировать, не продавать, а также не использовать в каких бы
                  то ни было целях информацию и материалы, ставшие ему
                  доступными в связи с оказанием Услуг, за исключением личного
                  использования непосредственно самим Заказчиком без
                  предоставления в какой-либо форме доступа каким-либо третьим
                  лицам.
                </p>
              </li>
              <li>
                <p>
                  Заказчик обязуется принять Услуги, оказанные Исполнителем;
                </p>
              </li>
              <li>
                <p>
                  Заказчик вправе потребовать от Исполнителя вернуть денежные
                  средства за неоказанные услуги, некачественно оказанные
                  услуги, услуги, оказанные с нарушением сроков оказания, а
                  также, если Заказчик решил отказаться от услуг по причинам, не
                  связанным с нарушением обязательств со стороны Исполнителя,
                  исключительно по основаниям, предусмотренным действующим
                  законодательством Российской Федерации.
                </p>
              </li>
              <li>
                <p>
                  Заказчик гарантирует, что все условия Договора ему понятны;
                  Заказчик принимает условия без оговорок, а также в полном
                  объеме.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </ol>
      <p>
        <br />{" "}
      </p>
      <p style={textCenter}>
        <strong>Цена и порядок расчетов</strong>
      </p>
      <ol>
        <li>
          <p>
            Стоимость услуг Исполнителя, оказываемых Заказчиком и порядок их
            оплаты, определяются на основании сведений Исполнителя при
            оформлении заявки Заказчиком либо устанавливаются на Сайте
            Исполнителя в сети &laquo;Интернет&raquo;: https://tools.ptahini.ru/
          </p>
        </li>
        <li>
          <p>Все расчеты по Договору производятся в безналичном порядке.</p>
        </li>
      </ol>
      <p>
        <strong>Конфиденциальность и безопасность</strong>
      </p>
      <ol>
        <li>
          <p>
            При реализации настоящего Договора Стороны обеспечивают
            конфиденциальность и безопасность персональных данных в соответствии
            с актуальной редакцией ФЗ от 27.07.2006 г. № 152-ФЗ &laquo;О
            персональных данных&raquo; и ФЗ от 27.07.2006 г. № 149-ФЗ &laquo;Об
            информации, информационных технологиях и о защите информации&raquo;.
          </p>
        </li>
        <li>
          <p>
            Стороны обязуются сохранять конфиденциальность информации,
            полученной в ходе исполнения настоящего Договора, и принять все
            возможные меры, чтобы предохранить полученную информацию от
            разглашения.
          </p>
        </li>
        <li>
          <p>
            Под конфиденциальной информацией понимается любая информация,
            передаваемая Исполнителем и Заказчиком в процессе реализации
            Договора и подлежащая защите, исключения указаны ниже.
          </p>
        </li>
        <li>
          <p>
            Такая информация может содержаться в предоставляемых Исполнителю
            локальных нормативных актах, договорах, письмах, отчетах,
            аналитических материалах, результатах исследований, схемах,
            графиках, спецификациях и других документах, оформленных как на
            бумажных, так и на электронных носителях.
          </p>
        </li>
      </ol>
      <p style={textCenter}>
        <strong>Форс-мажор</strong>
      </p>
      <ol>
        <li>
          <p>
            Стороны освобождаются от ответственности за неисполнение или
            ненадлежащее исполнение обязательств по Договору, если надлежащее
            исполнение оказалось невозможным вследствие непреодолимой силы, то
            есть чрезвычайных и непредотвратимых при данных условиях
            обстоятельств, под которыми понимаются: запретные действия властей,
            эпидемии, блокада, эмбарго, землетрясения, наводнения, пожары или
            другие стихийные бедствия.{" "}
          </p>
        </li>
        <li>
          <p>
            В случае наступления этих обстоятельств Сторона обязана в течение 30
            (Тридцати) рабочих дней уведомить об этом другую Сторону.{" "}
          </p>
        </li>
        <li>
          <p>
            Документ, выданный уполномоченным государственным органом, является
            достаточным подтверждением наличия и продолжительности действия
            непреодолимой силы.{" "}
          </p>
        </li>
        <li>
          <p>
            Если обстоятельства непреодолимой силы продолжают действовать более
            60 (Шестидесяти) рабочих дней, то каждая Сторона вправе отказаться
            от настоящего Договора в одностороннем порядке.{" "}
          </p>
        </li>
      </ol>
      <p style={textCenter}>
        <strong>Ответственность Сторон</strong>
      </p>
      <ol>
        <li>
          <p>
            В случае неисполнения и/или ненадлежащего исполнения своих
            обязательств по Договору, Стороны несут ответственность в
            соответствии с условиями настоящей Оферты.
          </p>
        </li>
        <li>
          <p>
            Исполнитель не несет ответственности за неисполнение и/или
            ненадлежащее исполнение обязательств по Договору, если такое
            неисполнение и/или ненадлежащее исполнение произошло по вине
            Заказчика.
          </p>
        </li>
        <li>
          <p>
            Сторона, не исполнившая или ненадлежащим образом исполнившая
            обязательства по Договору, обязана возместить другой Стороне
            причиненные такими нарушениями убытки.
          </p>
        </li>
      </ol>
      <p style={textCenter}>
        <strong>Срок действия настоящей Оферты</strong>
      </p>
      <ol>
        <li>
          <p>
            Оферта вступает в силу с момента размещения на Сайте Исполнителя и
            действует до момента её отзыва Исполнителем.
          </p>
        </li>
        <li>
          <p>
            Исполнитель оставляет за собой право внести изменения в условия
            Оферты и/или отозвать Оферту в любой момент по своему усмотрению.
            Сведения об изменении или отзыве Оферты доводятся до Заказчика по
            выбору Исполнителя посредством размещения на сайте Исполнителя в
            сети &laquo;Интернет&raquo;, в Личном кабинете Заказчика, либо путем
            направления соответствующего уведомления на электронный или почтовый
            адрес, указанный Заказчиком при заключении Договора или в ходе его
            исполнения.
          </p>
        </li>
        <li>
          <p>
            Договор вступает в силу с момента Акцепта условий Оферты Заказчиком
            и действует до полного исполнения Сторонами обязательств по
            Договору.
          </p>
        </li>
        <li>
          <p>
            Изменения, внесенные Исполнителем в Договор и опубликованные на
            сайте в форме актуализированной Оферты, считаются принятыми
            Заказчиком в полном объеме.
          </p>
        </li>
      </ol>
      <p style={textCenter}>
        <strong>Дополнительные условия</strong>
      </p>
      <ol>
        <li>
          <p>
            Договор, его заключение и исполнение регулируется действующим
            законодательством Российской Федерации. Все вопросы, не
            урегулированные настоящей Офертой или урегулированные не полностью,
            регулируются в соответствии с материальным правом Российской
            Федерации.
          </p>
        </li>
        <li>
          <p>
            В случае возникновения спора, который может возникнуть между
            Сторонами в ходе исполнения ими своих обязательств по Договору,
            заключенному на условиях настоящей Оферты, Стороны обязаны
            урегулировать спор мирным путем до начала судебного разбирательства.{" "}
          </p>
        </li>
      </ol>
      <p>
        Судебное разбирательство осуществляется в соответствии с
        законодательством Российской Федерации.{" "}
      </p>
      <p>
        Споры или разногласия, по которым Стороны не достигли договоренности,
        подлежат разрешению в соответствии с законодательством РФ. Досудебный
        порядок урегулирования спора является обязательным.
      </p>
      <ol>
        <ol start="3">
          <li>
            <p>
              В качестве языка Договора, заключаемого на условиях настоящей
              Оферты, а также языка, используемого при любом взаимодействии
              Сторон (включая ведение переписки, предоставление требований /
              уведомлений / разъяснений, предоставление документов и т. д.),
              Стороны определили русский язык.
            </p>
          </li>
          <li>
            <p>
              Все документы, подлежащие предоставлению в соответствии с
              условиями настоящей Оферты, должны быть составлены на русском
              языке либо иметь перевод на русский язык, удостоверенный в
              установленном порядке.
            </p>
          </li>
          <li>
            <p>
              Бездействие одной из Сторон в случае нарушения условий настоящей
              Оферты не лишает права заинтересованной Стороны осуществлять
              защиту своих интересов позднее, а также не означает отказа от
              своих прав в случае совершения одной из Сторон подобных либо
              сходных нарушений в будущем.
            </p>
          </li>
          <li>
            <p>
              Если на Сайте Исполнителя в сети &laquo;Интернет&raquo; есть
              ссылки на другие веб-сайты и материалы третьих лиц, такие ссылки
              размещены исключительно в целях информирования, и Исполнитель не
              имеет контроля в отношении содержания таких сайтов или материалов.
              Исполнитель не несет ответственность за любые убытки или ущерб,
              которые могут возникнуть в результате использования таких ссылок.
            </p>
          </li>
        </ol>
      </ol>
      <p style={textCenter}>
        <strong>
          Реквизиты Исполнителя
          <br />{" "}
        </strong>
      </p>
      <p>Полное наименование: Бабий Ксения Юрьевна</p>
      <p>Контактный телефон: 8 (977) 934-07-02</p>
      <p>Контактный e-mail: app@ptahini.ru</p>
    </div>
  );
}
