import React from "react";
import s from "./Footer.module.css";

export default function MailContact() {
  return (
    <div className={s.mailFooter}>
      <a href="mailto:app@ptahini.ru">app@ptahini.ru</a>
    </div>
  );
}
